#page {
  padding: 2em;
}

h1 {
  margin-bottom: 1em;
  font-family: "AlphaLyrae";
}

#crossword-wrapper .clue.correct::before {
  content: "✓";
  display: inline-block;
  text-decoration: none;
  color: rgb(100, 200, 100);
  margin-right: 0.25em;
}

#crossword-wrapper {
  margin-top: 2em;
}

#messages {
  background-color: rgb(230, 230, 230);
  margin: 1em 0;
  padding: 1em;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/static/Inter-Regular.ttf") format("ttf"),
    url("./fonts/static/Inter-Medium.ttf") format("ttf");
}

body {
  margin: 0;
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span.important {
  font-size: 1.9rem;
  color: #ff585d;
}

.claim-inputs {
  margin-top: 30px;
}

.claim-inputs label {
  font-size: 1.3em;
}

/*#claim-memo, #claim-account-id, .btn {*/
/*    border-radius: 5px;*/
/*    border: .1em solid #000;*/
/*    padding: .5em 1em;*/
/*    font-size: 1.66em;*/
/*    margin: .5em;*/
/*}*/

/*.claim-inputs .btn {*/
/*    margin: .5em;*/
/*}*/

#page h1,
#page.no-puzzles {
  text-align: center;
}

#page.claim {
  margin: 0 auto;
  width: 576px;
}

#claim-status {
  background-color: #ff585d;
  border-radius: 6px;
}

#claim-status.hide {
  display: none;
}

#claim-status.show {
  display: block;
}

#claim-status p {
  padding: 1.5em;
  font-size: 1.1em;
  font-weight: bold;
  color: #ffffff;
}

#seed-phrase-wrapper {
  display: none;
  background-color: #faf8d1;
  border-radius: 6px;
}

#seed-phrase-wrapper.hide {
  display: none;
}

#seed-phrase-wrapper.show {
  display: block;
}

#seed-phrase-wrapper h3 {
  padding: 0.5em 0.5em 0;
}

#seed-phrase-wrapper p {
  padding: 0.5em;
  font-size: 1.3em;
}

#seed-phrase-wrapper #seed-phrase {
  font-size: 1.7em;
  color: #ff585d;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.main-area .grid.success-links {
  display: grid;
  max-width: 100%;
  margin-top: 50px;
}

.success-links .success-links-center {
  margin: 0 auto;
}

.success-link {
  width: 260px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 6px 32px rgba(167, 167, 167, 0.1);
  border-radius: 12px;
  margin: 24px;
  padding: 27px;
}

.no-puzzles .btn {
  height: 75px;
}

.no-puzzles .success-link {
  padding: 32px 25px 32px;
  height: 170px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #262626;
}

.no-puzzles .near-link {
  margin-top: 28px;
  padding: 15px 0;
  height: 50px;
}
.no-puzzles .bridge-text {
  width: 100%;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin: 0 0 10px;
  color: #262626;
}

.success-links {
  text-align: center;
  justify-content: center;
}

@media screen and (max-width: 1080px) {
  .success-links {
    flex-wrap: wrap;
    justify-content: center;
  }
}
